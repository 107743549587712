body {
  margin: 0;
  /* background-color: '#121212'; */
  font-family: 'Verdana', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-width: .7em;
  scrollbar-height: .7em;
  /* border-radius: 4px; */
  /* scrollbar-color: #e1e1e1 #212121; */
}

*::-webkit-scrollbar {
  background: #212121;
  width: .7em;
  height: .7em;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: #212121;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 4px;
  width: 70%;
  height: 70%;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
